import { CognitoGroups, DemoBikesRoles } from '@/utils/constants'
import {
  StatsLeadsResponse,
  StatsDemoBikesResponse,
  StatsOffersResponse,
  StatsRegistrationsResponse,
  StatsSalesResponse,
  StatsAppointmentsResponse,
} from '@/types'

export const mapLeadsData = (data: StatsLeadsResponse | any) => {
  if (!data) {
    return {}
  }

  return {
    year: data.year,
    month: data.month,
    leads: data.leads,
    leadsYoy: data.leads_yoy,
    attended48h: data.attended_48h,
    attended48hPerc: data.attended_48h_perc,
    attended48hTarget: data.attended_48h_target,
    attended48hStatus: data.attended_48h_status,
    withOffer: data.with_offer,
    withOfferPerc: data.with_offer_perc,
    withOfferTarget: data.with_offer_target,
    withOfferStatus: data.with_offer_status,
  }
}

export const mapDemoBikesData = (data: StatsDemoBikesResponse | any, role: DemoBikesRoles) => {
  if (!data || !role) {
    return {}
  }

  const commonData = {
    year: data.year,
    month: data.month,
    demoBikesTarget: data.demobikes_target,
    loansSalesTarget: data.loans_vs_sales_target,
  }

  if ([CognitoGroups.SalesAgent].includes(role)) {
    return {
      ...commonData,
      demoBikes: data.demo_demobikes,
      demoBikesPerc: data.demo_demobikes_perc,
      demoBikesStatus: data.demo_demobikes_status,
      loans: data.demo_loans,
      loansYoy: data.demo_loans_yoy,
      loanedDemoBikes: data.demo_loaned_demobikes,
      loansSalesPerc: data.demo_loans_vs_sales_perc,
    }
  }

  if ([CognitoGroups.GarageManager].includes(role)) {
    return {
      ...commonData,
      demoBikes: data.courtesy_demobikes,
      demoBikesPerc: data.courtesy_demobikes_perc,
      demoBikesStatus: data.courtesy_demobikes_status,
      loans: data.courtesy_loans,
      loansYoy: data.courtesy_loans_yoy,
      loanedDemoBikes: data.courtesy_loaned_demobikes,
      loansSalesPerc: data.courtesy_loans_vs_sales_perc,
    }
  }

  if ([CognitoGroups.Admin, CognitoGroups.DealerAdmin].includes(role)) {
    return {
      ...commonData,
      demoBikes: data.demobikes,
      demoBikesPerc: data.demobikes_perc,
      demoBikesStatus: data.demobikes_status,
      loans: data.loans,
      loansYoy: data.loans_yoy,
      loanedDemoBikes: data.loaned_demobikes,
      loansSalesPerc: data.loans_vs_sales_perc,
    }
  }
}

export const mapOffersData = (data: StatsOffersResponse | any) => {
  if (!data) {
    return {}
  }

  return {
    year: data.year,
    month: data.month,
    offers: data.offers,
    offersYoy: data.offers_yoy,
    coiCreated: data.coi_created,
    coiCreatedYoy: data.coi_created_yoy,
    coiCreatedPerc: data.coi_created_perc,
    toBeDelivered: data.to_be_delivered,
    currentInvoiced: data.current_invoiced,
    currentConfirmed: data.current_confirmed,
    currentQuote: data.current_quote,
    currentCoiAccepted: data.current_coi_created,
    currentAccepted: data.current_accepted,
  }
}

export const mapRegistrationsData = (data: StatsRegistrationsResponse | any) => {
  if (!data) {
    return {}
  }

  return {
    year: data.year,
    month: data.month,
    hondaPlus: data.hp,
    hondaPlusYoy: data.hp_yoy,
    mapitPerc: numberToPercent(data.hm_perc),
    insurancePerc: numberToPercent(data.ins_perc),
    financedPerc: numberToPercent(data.fin_perc),
    hondaPlusGo: data.hpgo,
    hondaPlusGoPerc: data.hpgo_perc,
  }
}

export const mapPreDeliveriesData = (data: StatsSalesResponse | any) => {
  if (!data) {
    return {}
  }

  return {
    year: data.year,
    month: data.month,
    sales: data.sales,
    salesTarget100: data.sales_target_100,
    salesTarget105: data.sales_target_105,
    salesWeight: data.weight,
    salesWeightTarget: data.weight_target,
    salesStatus: data.sales_status,
    hondaPlus: data.hp,
    hondaPlusPerc: data.hp_perc,
    hondaPlusObj: data.hp_obj,
    hondaPlusTarget: data.hp_target,
    hondaPlusStatus: data.hp_status,
    lastDelivery: data.last_predelivery_at,
  }
}

export const mapAppointmentsData = (data: StatsAppointmentsResponse | any) => {
  if (!data) {
    return {}
  }

  return {
    year: data.year,
    month: data.month,
    appointmentsPending: data.pending_appointments,
    appointmentsScheduled: data.accepted,
    appointmentsFinished: data.finalized_with_appointment,
    appointmentsFinishedPerc: data.kpi_finalized_accepted,
  }
}

function numberToPercent(value: number): string {
  return !value && value !== 0 ? '' : `${value}%`
}
