import moment from 'moment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'
import pt from './locales/pt.json'

Vue.use(VueI18n)

const locale = navigator.language.substring(0,2).toLowerCase() || 'es'

moment.locale(navigator.language)

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'es',
  messages: {
    en,
    es,
    pt
  }
})

export default i18n
export const useI18n = () => ({
  i18n,
  t: (key, options) => i18n.t(key, options),
})
