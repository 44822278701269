






import {defineComponent} from "@vue/composition-api";

import LeadsTable from "@/components/leads/LeadsTable.vue";
import CardWrapper from "@/components/general/CardWrapper.vue";

export default defineComponent({
  name: "Leads",
  components: {LeadsTable, CardWrapper},
  setup() {
    return {};
  }
})
