




























































































































































































































import { DateTime } from "luxon";
import { computed, defineComponent, ref } from "@vue/composition-api";

import { mapGetters } from "@/utils/map-store";
import { OpportunityType, LeadStatus, LeadSummary } from "@mapit/leads-dynamodb/dist/types";
import { toDateFormat, isValidDate } from "@/utils/date-helper";
import { useLeadsList } from '@/composables'
import { LogItem } from "@/types";

import LabelTooltip from "@/components/text/LabelTooltip.vue"
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import MCopy from '@/components/atom/MCopy.vue'

enum OpportunityTypeLegacy {
  Lease = "LEASE",
  DemoBikeRequest = "DEMO_BIKE_REQUEST",
}

export default defineComponent({
  name: "LeadsTable",
  components: {MCopy, LabelTooltip, MapitDialog},
  setup(props, {root}) {
    const {
      data: leadList,
      isLoading,
      markLeadAsRead,
      maskLeadAsLost
    } = useLeadsList()
    const selectedLead = ref<LeadSummary | null>(null)
    const selectedStatus = ref<Array<string>>([LeadStatus.New, LeadStatus.InProgress])
    const selectedType = ref<Array<string>>([...Object.values(OpportunityType), ...Object.values(OpportunityTypeLegacy)])
    const search = ref<string | undefined>(undefined)
    const dialog = ref<boolean>(false)

    const {
      getLocale: locale
    } = mapGetters(root.$store.getters)

    const filteredLeadList = computed(() => {
      return leadList.value
        .filter(item => selectedStatus.value.length > 0 ? selectedStatus.value.includes(item.status || "") : true)
        .filter(item => selectedType.value.length > 0 ? selectedType.value.includes(item.type || "") : true)
        .filter(item => search.value && search.value !== ""
          ? item.prospectName?.toLowerCase().includes(search.value?.toLowerCase())
          || root.$t(`leads.types.${item.type}`).toString().toLowerCase().includes(search.value?.toLowerCase())
          || item.assignee?.toLowerCase().includes(search.value?.toLowerCase())
          : true)
    })

    const typeList = computed(() => {
      return [...Object.values(OpportunityType), ...Object.values(OpportunityTypeLegacy)]
        .map(type => ({text: root.$t(`leads.types.${type}`).toString(), value: type}))
    })

    const filterList = computed(() => {
      return Object.values(LeadStatus)
        .map(status => ({text: root.$t(`leads.status.${status}`).toString(), value: status}))
    })

    const headers = [
      {
        text: root.$t('leads.list.createdAt'),
        align: 'left',
        value: 'createdAt',
        class: 'text-header',
        sortable: true,
        sort: (a: string, b: string) => (sameDay(a, b) ? compareDates(a, b) : b?.localeCompare(a))
      },
      {
        text: root.$t('leads.list.type'),
        align: 'left',
        value: 'type',
        class: 'text-header',
        sortable: true,
      },
      {
        text: root.$t('leads.list.campaign'),
        align: 'left',
        value: 'campaignName',
        class: 'text-header',
        sortable: true,
      },
      {
        text: root.$t('leads.list.client'),
        align: 'left',
        value: 'prospectName',
        class: 'text-header',
        sortable: true,
      },
      {
        text: root.$t('leads.list.agent'),
        align: 'left',
        value: 'assignee',
        class: 'text-header',
        sortable: true,
        sort: (a: string, b: string) => (a?.localeCompare(b as string))
      },
      {
        text: root.$t('leads.list.status'),
        align: 'left',
        value: 'status',
        class: 'text-header',
        sortable: true,
        sort: (a: LeadStatus, b: LeadStatus) => statusWeight(a) - statusWeight(b)
      },
      {
        text: root.$t('leads.list.offer'),
        align: 'center',
        value: 'offerId',
        class: 'text-header',
        sortable: true,
        width: '148px',
        sort: (a: string, b: string) => ((a ? 0 : 1) - (b ? 0 : 1))
      },
      {
        text: root.$t('leads.list.follow-up'),
        align: 'center',
        value: 'followUp',
        class: 'text-header',
        sortable: true,
        sort: sortFollowUps
      },
      {
        text: root.$t('leads.list.action'),
        align: 'center',
        value: 'actions',
        class: 'text-header',
        width: '5%',
        sortable: false,
      },
    ]

    function sameDay(a: string, b: string) {
      return a?.localeCompare(b) === 0
    }

    function compareDates(a: string, b: string) {
      return DateTime.fromISO(a) < DateTime.fromISO(b) ? 1 : -1
    }

    function sortFollowUps(a: any, b: LogItem) {
      return !b?.dueDate || DateTime.fromISO(b.dueDate) < DateTime.fromISO(a?.dueDate) ? 1 : -1
    }

    function getCreatedAtDate(created: string) {
      return toDateFormat(created, locale.value)
    }

    function getFollowUpDate(date: string) {
      return isValidDate(date) ? toDateFormat(date, locale.value) : undefined
    }

    function isExpiredDate(date: string) {
      return isValidDate(date) && DateTime.fromISO(date) < DateTime.local().startOf('day') ? 'expired' : ''
    }

    function statusWeight(a: LeadStatus) {
      switch (a) {
        case LeadStatus.New:
          return -1
        case LeadStatus.InProgress:
          return 0
        case LeadStatus.WithOffer:
          return 1
        case LeadStatus.Lost:
          return 2
        default:
          return 3
      }
    }

    function viewLead(id: string) {
      root.$router.push({name: 'LeadDetail', params: {id}})
    }

    function getRowClass(row: LeadSummary) {
      return row.read ? 'click lead-item' : 'click lead-item__unread'
    }

    function onRowClick(row: LeadSummary) {
      viewLead(row.id)
    }

    function onCopyClick() {
      root.$store.dispatch('showAlertSuccess', "leads.list.copy-success")
    }

    function createOffer(lead: LeadSummary) {
      root.$router.push({name: 'CreateOfferMotorbike', params: { leadId: lead.id }})
    }

    function closeConfirmDialog() {
      dialog.value = false
      selectedLead.value = null
    }

    function openConfirmDialog(lead: LeadSummary) {
      dialog.value = true
      selectedLead.value = lead
    }

    function confirmLostLead() {
      if(selectedLead.value) maskLeadAsLost(selectedLead.value)
      closeConfirmDialog()
    }

    function getItemType(item: LeadSummary) {
      return root.$te(`leads.types.${item.type}`) ? root.$t(`leads.types.${item.type}`) : item.type
    }

    return {
      leadList,
      typeList,
      filterList,
      filteredLeadList,
      selectedStatus,
      selectedType,
      search,
      loading: isLoading,
      headers,
      dialog,
      markLeadAsRead,
      sameDay,
      compareDates,
      getCreatedAtDate,
      viewLead,
      getRowClass,
      onRowClick,
      onCopyClick,
      createOffer,
      LeadStatus,
      confirmLostLead,
      openConfirmDialog,
      closeConfirmDialog,
      getItemType,
      getFollowUpDate,
      isExpiredDate
    };
  }
})
