<template>
  <v-row no-gutters>
    <v-col class="col-5 m-t-15 col-md-5 col-sm-12 bold-900">
      MOTO DE CORTESÍA
    </v-col>
    <v-col class="col-7 col-md-7 col-sm-12">
      <v-row>
        <v-col class="col-6 col-md-6 col-sm-12">
          <v-row no-gutters>
            <div class="col-12 subtitle">
              SOLICITADA POR EL CLIENTE
            </div>
            <div class="col-12">
              <select id="courtesy-requested-select" v-model="courtesy.requested" class="form-select"
                      :disabled="!editable"
              >
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div>
          </v-row>
        </v-col>
        <v-col class="col-6 col-md-6 col-sm-12">
          <v-row no-gutters>
            <v-col class="col-12 subtitle">
              DISPONIBILIDAD
            </v-col>
            <v-col class="col-12">
              <select id="courtesy-availability" v-model="courtesy.status" class="form-select"
                      :disabled="!courtesy.requested || courtesy.requested === 'false'"
              >
                <option value="NOT_AVAILABLE">NO DISPONIBLE</option>
                <option value="ACCEPTED">DISPONIBLE</option>
              </select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    props: {
      courtesy: {type: Object},
      editable: {
        type: Boolean,
        default: false
      }
    },
    created() {
      if (this.courtesy.status != "NOT_AVAILABLE" && this.courtesy.status != "ACCEPTED"){
        this.courtesy.status = "NOT_AVAILABLE";
      }
      this.courtesy.requested = this.courtesy.requested || false
    },
  }
</script>
<style scoped>
    .subtitle{
        font-family: 'ProximaNovaExCnLight','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 14px;
        color: #888;
    }
</style>
